html {
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: Helvetica, Arial, sans-serif;
}

p {
  margin: 0;
}

#intro, iframe, footer {
  margin: 25px 0;
  padding: 0 25px;
}

#intro {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

#intro p {
  margin: 10px 0;
}

#intro p:first-child {
  margin-top: 0;
}

#intro p:last-child {
  margin-bottom: 0;
}

#question {
  font-size: 3em;
}

#cta, #spanish {
  font-size: 1.3em;
}

iframe {
  border: none;
  margin: 0 auto;
  display: block;
}

@media screen and (orientation: landscape) {
  iframe {
    width: 60vw;
    height: 33.7496vw;
  }
}

@media screen and (orientation: portrait) {
  iframe {
    width: 100vw;
    height: 56.2493vw;
  }
}

footer {
  max-width: 1000px;
  text-align: justify;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

footer p {
  margin: 15px 0;
}

footer p:first-child {
  margin-top: 0;
}

footer p:last-child {
  margin-bottom: 0;
}

/*# sourceMappingURL=index.4e0a0a1a.css.map */
