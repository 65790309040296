html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  font-family: Helvetica, Arial, sans-serif;
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
}

/* Give these elements some space on mobile so that they are not flush with the
   viewport. This also ensures that the iframe and the footer align on mobile. */
#intro,
iframe,
footer {
  margin: 25px 0;
  padding: 0 25px;
}

#intro {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

#intro p {
  margin: 10px 0;
}

#intro p:first-child {
  margin-top: 0;
}

#intro p:last-child {
  margin-bottom: 0;
}

#question {
  font-size: 3em;
}

#cta,
#spanish {
  font-size: 1.3em;
}

iframe {
  border: none;
  display: block;
  margin: 0 auto;
}

@media screen and (orientation: landscape) {
  iframe {
    width: 60vw;
    height: calc(60vw / 1.7778);
  }
}

@media screen and (orientation: portrait) {
  iframe {
    width: 100vw;
    height: calc(100vw / 1.7778);
  }
}

footer {
  margin-left: auto;
  margin-right: auto;
  max-width: 1000px;
  text-align: justify;
  width: 100%;
}

footer p {
  margin: 15px 0;
}

footer p:first-child {
  margin-top: 0;
}

footer p:last-child {
  margin-bottom: 0;
}
